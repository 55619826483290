import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import { isProd } from '../client';

export const ScriptsBefore = () => {
  /* eslint-disable prefer-destructuring -- Gatsby env can't be destructured */
  const COOKIES_CODE = process.env.GATSBY_COOKIES_CODE;
  const GA_CODE = process.env.GATSBY_GA_CODE;

  return (
    <>
      {/* // OneTrust Cookies Consent Notice */}
      {COOKIES_CODE !== undefined && COOKIES_CODE !== '' && (
        <>
          <Script
            strategy={ScriptStrategy.postHydrate}
            type="text/javascript"
            src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
            data-domain-script={`${COOKIES_CODE}${!isProd ? '-test' : ''}`}
          />
          <Script
            strategy={ScriptStrategy.postHydrate}
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
          />
        </>
      )}

      {/* GTM */}
      {GA_CODE !== undefined && GA_CODE !== '' && (
        <Script strategy={ScriptStrategy.postHydrate}>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GA_CODE}');
          `}
        </Script>
      )}
    </>
  );
};
